<script lang="ts" setup>
import type { RecentWinner } from "@/types";
const { t } = useT();
const props = defineProps<{ item: RecentWinner; superchargedBox?: boolean }>();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const defaultTypesDictionary = new Map([
	["jackpot", "jackpot"],
	["daily-chests-accrual", "Magic box"],
	["daily-wheel-accrual", "fortune wheel"]
]);

const isGuest = useIsGuest();
const { open } = useAppModals();
const router = useRouter();
const { playMysteryBoxAudio } = useMysteryBoxAudios();
const isMagicBox = computed(() => props.item.type === "daily-chests-accrual");
const isDailyWheel = computed(() => props.item.type === "daily-wheel-accrual");
const isJackpot = computed(() => props.item.type === "jackpot");

const { handleOpenGame } = useOpenGame(open, !isMagicBox.value);
const { isEventBox } = useEventsCollector();

const recentImageMystery = computed(() => {
	if (isEventBox.value) {
		return "/nuxt-img/activity-feed/daily-chests-accrual-bg-dice.jpg";
	}

	if (props.superchargedBox) {
		return "/nuxt-img/activity-feed/daily-chests-accrual-bg-super.jpg";
	}

	return "/nuxt-img/activity-feed/daily-chests-accrual-bg.webp";
});

const handleCheck = (winner: RecentWinner) => {
	if (isGuest.value) {
		open("LazyOModalSignup");
		return;
	}
	if (isMagicBox.value) {
		open("LazyOModalMysteryBox");
		playMysteryBoxAudio();
		return;
	}
	if (isDailyWheel.value) {
		router.push("/vip-club/");
		return;
	}
	handleOpenGame(winner.data?.slug);
};
</script>

<template>
	<div class="border-wrapper" @click="handleCheck(item)">
		<div class="feed-card" :class="'feed-card--' + item.type">
			<div class="feed-card__image" :class="{ 'with-pointer': isMagicBox || isJackpot ? isGuest : true }">
				<NuxtImg
					v-if="isMagicBox"
					:src="recentImageMystery"
					:alt="item.type"
					:data-tid="item?.data?.slug"
					width="144"
					height="160"
					format="webp"
					loading="lazy"
				/>
				<NuxtImg
					v-else-if="defaultTypesDictionary.has(item.type)"
					:src="`/nuxt-img/activity-feed/${item.type}-bg.png`"
					:alt="item.type"
					:data-tid="item?.data?.slug"
				/>
				<NuxtImg
					v-else
					:src="`${baseImageUrl}${item.data?.logo}`"
					:alt="item.data?.slug"
					:data-tid="item?.data?.slug"
					width="72"
					height="78"
					format="webp"
				/>
			</div>
			<div class="feed-card__data">
				<div class="feed-card__text">
					<AText variant="tonk" :modifiers="['semibold']" class="app-text-clip text-chanda" data-tid="winner-name">
						{{ item.name || item.nickname }} {{ item.surname }}
					</AText>
					<AText
						v-if="item.name && (item.city || item.state)"
						variant="tlalpan"
						:modifiers="['semibold']"
						class="text-canoas"
						data-tid="winner-location"
					>
						<span>{{ item.city }}</span>
						<span>{{ item.state ? `, ${item.state.toUpperCase()}` : "" }}</span>
					</AText>
				</div>
				<AText
					variant="toledo"
					:modifiers="['extrabold']"
					:gradient="item.type === 'bigwin' ? 'var(--goeteborg)' : ''"
					as="div"
					class="feed-card__values"
				>
					<template v-if="item.type === 'win'">
						<div
							v-if="item.data?.winAmount"
							class="feed-card__value"
							:class="
								item.gameMode === 'SweepStakes' && !isGuest ? 'feed-card__value--entries' : 'feed-card__value--coins'
							"
							:data-tid="`win-amount-${item?.gameMode}`"
						>
							{{ numberFormat(item.data?.winAmount) }}
						</div>
					</template>
					<template v-if="item.type === 'bigwin'">
						<span class="feed-card__value feed-card__value--x" :data-tid="`bigwin-amount-${item?.gameMode}`">
							{{ numberFormat(item.data?.big_win_coefficient || 0) }}
						</span>
					</template>
					<template v-if="item.type === 'jackpot'">
						<div
							class="feed-card__value"
							:class="
								item.gameMode === 'SweepStakes' && !isGuest ? 'feed-card__value--entries' : 'feed-card__value--coins'
							"
							:data-tid="`jackpot-amount-${item?.gameMode}`"
						>
							{{ numberFormat(item.data?.amount || 0) }}
						</div>
					</template>
					<template v-if="item.type === 'daily-chests-accrual'">
						<div
							v-if="item.data?.coins"
							class="feed-card__value feed-card__value--coins"
							:data-tid="`daily-wheel-amount-${item?.gameMode}`"
						>
							{{ numberFormat(item.data?.coins) }}
						</div>
						<div
							v-if="item.data?.entries"
							:class="`feed-card__value feed-card__value--${isGuest ? 'coins' : 'entries'}`"
							:data-tid="`daily-wheel-amount-${item?.gameMode}`"
						>
							{{ numberFormat(item.data?.entries) }}
						</div>
					</template>
				</AText>
			</div>
			<div class="feed-card__action" data-tid="winners-play">
				<ASvg name="40/play" class="icon" />
				<AText variant="tonk" :modifiers="['underline', 'medium']" class="text-cotonou play">{{ t("Play") }}</AText>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.border-wrapper {
	padding: 1px;
	background: var(--garoua);
	overflow: hidden;
	border-radius: 12px;
	margin-bottom: gutter(1);
	box-shadow: 5px 3px 7px 0 rgba(108, 0, 98, 0.7);
	transition: 0.2s;

	@include media-breakpoint-down(md) {
		margin-bottom: gutter(0.6);
	}

	&:hover {
		box-shadow: none;
		cursor: pointer;

		.play {
			text-decoration: none;
		}
	}
}
.feed-card {
	height: 94px;
	width: 100%;
	padding: gutter(1) gutter(3) gutter(1) gutter(1.5);
	border-radius: 12px;
	background: var(--carabanchel);
	display: flex;
	gap: 12px;
	position: relative;

	@include media-breakpoint-down(md) {
		height: 74px;
	}

	&__image {
		width: 72px;
		height: 100%;
		border-radius: 8px;
		overflow: hidden;
		background: var(--cuba);
		position: relative;
		display: flex;
		align-items: flex-end;
		justify-content: center;

		&.with-pointer {
			cursor: pointer;
		}

		@include media-breakpoint-down(md) {
			width: 56px;
		}

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
		}
	}

	&__game {
		position: relative;
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		text-align: center;
		text-transform: uppercase;
		color: var(--clara);
		text-shadow: 0 1px 1px var(--coronel);
		padding: 0 gutter(0.5) gutter(1.5);

		&:deep(span) {
			display: block;
			text-shadow: none;
			font-size: 14px;
			font-weight: 400;
			text-align: center;
		}
	}
	&__image-overlay {
		z-index: 1;
	}
	&__values {
		line-height: 1;
		margin-top: gutter(0.5);
		white-space: nowrap;
	}
	@at-root {
		%value-icon {
			align-self: center;
			content: "";
			width: 22px;
			height: 22px;
			margin: 0 gutter(0.25) 0 0;
			background-size: contain !important;
			background-repeat: no-repeat;
		}
	}
	&__value {
		display: inline-flex;
		align-items: center;
		font-style: normal;
		color: var(--cannes);
		margin-right: gutter(0.5);
		line-height: 1;
	}
	&__value--coins {
		color: var(--coyoacan);
		&::before {
			@extend %value-icon;
			background-image: url("/assets/icons/20/gold-coins.svg");
		}
	}
	&__value--entries {
		color: var(--сirebon);
		&::before {
			@extend %value-icon;
			background-image: url("/assets/icons/20/sweep-coins.svg");
		}
	}
	&__value--x::before {
		@extend %value-icon;
		background-image: url("/assets/icons/20/big-win.svg");
	}
	&__data {
		height: 100%;
		flex-grow: 2;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		padding: gutter(0.7) 0;
		overflow: hidden;

		@include media-breakpoint-down(md) {
			padding: 0;
		}
	}
	&__action {
		height: 100%;
		display: flex;
		align-items: center;
		gap: gutter(1);
		text-shadow: 0 0 6px var(--carapicuiba);

		.icon {
			font-size: 40px;

			@include media-breakpoint-down(md) {
				font-size: 30px;
			}
		}
	}
	&__text {
		white-space: nowrap;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}
	&__name {
		color: var(--cannes);
		&:first-letter,
		&:first-line {
			text-transform: capitalize;
		}
	}
	&__location {
		display: flex;
		justify-content: center;
		font-size: 14px;
		color: var(--cancun);
		white-space: nowrap;
		margin-top: gutter(0.25);
	}
	&__city {
		text-transform: lowercase;
		&:first-letter,
		&:first-line {
			text-transform: capitalize;
		}
	}
}
</style>
